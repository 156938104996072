import { studiesData } from "../../data";
import Header from "../Header";
import { Element } from "react-scroll";

const Portfolio = () => {
  return (
    <Element
      id="education"
      className="bg-secondaryBackground pt-10 pb-12  lg:pt[40px] lg:pb[40px]"
    >
      <div className="container mx-auto">
        <Header title={"Education"} />
        <div className=" p-6 flex flex-col sm:py-12">
          <ul className="list-none">
            <li className="rounded-lg">
              {studiesData.map((item, index) => (
                <div key={index}>
                  <div className="flex justify-center">
                    <div className="items-center flex flex-col">
                      <div className="border-l-2 h-full border-gray-400"></div>
                      <div className="bg-hoverBg border-2 border-gray-400 rounded-full h-8 w-8 flex flex-grow justify-around">
                        <svg
                          className="flex-none m-2 w-4 h-4 opacity-0 "
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          ></path>
                        </svg>
                      </div>
                      <div className="border-l-2 h-full border-gray-400"></div>
                    </div>
                    <div className="flex flex-col  ml-2 p-2 pr-6 rounded-xl">
                      <div className="ml-4 text-xl font-medium text-textColor">
                        {item.title}
                      </div>
                      <div className="ml-4 mb-2 text-sm text-textSecondary">
                        {item.year}
                      </div>
                      <div className="ml-4 text-md text-textSecondary">
                        {item.subtitle}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </li>
          </ul>
        </div>
      </div>
    </Element>
  );
};

export default Portfolio;
