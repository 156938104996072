const Terminal = ({ title, data }) => {
  return (
    <div
      className="coding inverse-toggle px-2 shadow-lg text-gray-100 text-sm font-mono subpixel-antialiased 
              bg-gray-800  pb-6 pt-4 rounded-lg leading-normal overflow-hidden  hover:translate-y-2 hover:scale-110"
    >
      <div className="top mb-2 flex">
        <div className="h-3 w-3 bg-red-500 rounded-full"></div>
        <div className="ml-2 h-3 w-3 bg-orange-300 rounded-full"></div>
        <div className="ml-2 h-3 w-3 bg-green-500 rounded-full"></div>
      </div>
      <div className="mt-4 flex flex-col">
        <div>
          <p className="text-green-400">
            {title}:~$
            <span className="flex-1 text-textSecondary typing items-center pl-2">
              ls
            </span>
          </p>
        </div>
        <div>
          {data.map((item, index) => (
            <p className="flex-1 typing items-center pt-4 pl-5" key={index}>
              {" "}
              {item.name}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Terminal;
