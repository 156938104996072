import Home from "./components/Home";
import "./App.css";
import About from "./components/About";
import Services from "./components/Services";
import Education from "./components/Education";
import Portfolio from "./components/Portfolio";
import Navbar from "./components/Navbar";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <Navbar />
      <div>
        <Home />
        <About />
        <Services />
        <Education />
        {/* <Portfolio /> */}
        {/* <Team/> */}
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;
