import {
  SiSimpleanalytics,
  SiMicrostrategy,
  SiGooglemarketingplatform,
} from "react-icons/si";
import { CgWebsite } from "react-icons/cg";
import { MdOutlineDeveloperMode } from "react-icons/md";
import { GoReport } from "react-icons/go";
import {
  FaFacebook,
  FaFilePdf,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import image1 from "../images/1.jpg";
import image2 from "../images/2.jpg";
import image3 from "../images/3.jpg";
import image5 from "../images/5.jpg";
import image6 from "../images/6.jpg";
import image7 from "../images/7.jpg";

export const navigation = [
  { name: "Home", href: "#", id: "home" },
  { name: "About", href: "#", id: "about" },
  { name: "Stacks", href: "#", id: "services" },
  // { name: "Portfolio", href: "#", id: "portfolio" },
  { name: "Education", href: "#", id: "education" },
];

export const aboutMeData = [
  {
    id: 1,
    bio: "Diligent professional with 12+ years of work experience and a proven knowledge of advanced technology, benchmarking & capacity planning. ",
  },
  {
    id: 2,
    bio: "Worked with frameworks that includes but not limited to Nest, Next, Nuxt, Laravel, Vue, React, Redux, React Native, Angular, Node js, Typescript, Tailwind, Npm, Yarn, Ajax,  AWS, Docker, Azure, Mongo, SQL,  Ploi,  Jquery, Rest, GraphQl API’s, Bootstrap, vanilla Js, MERN, MEAN and MEVN stacks.",
  },
  {
    id: 3,
    bio: "Responsible for end-to-end development and deployment of websites and mobile applications including IOS and android updates.",
  },
  {
    id: 4,
    bio: "Also, a Graphic designer, photographer, and videographer. Hard worker experienced in problem-solving, service and time management.",
  },
];

export const counterData = [
  {
    id: "experienceCounter",
    title: "Years of experience",
    measurement: "",
  },

  {
    id: "feedbackCounter",
    title: "Positive feedback",
    measurement: "%",
  },
  {
    id: "projectsCounter",
    title: "Awards",
    measurement: "+",
  },
];

export const studiesData = [
  {
    title: "Master of Arts (M.A.) in Multimedia  ",
    year: "March 2010",
    subtitle: "Monash University, Melbourne",
  },
  {
    title: "Bachelor of Science (B.S.) in Computing ",
    year: "April 2006 ",
    subtitle: "Anna University, India",
  },
];

export const languagesData = [
  {
    id: 1,
    name: "PHP",
  },
  {
    id: 2,
    name: "TypeScript",
  },
  {
    id: 3,
    name: "JavaScript",
  },
  {
    id: 4,
    name: "HTML/CSS Tailwind",
  },
];

export const dbData = [
  {
    id: 1,
    name: "MySQL",
  },
  {
    id: 2,
    name: "MongoDB",
  },
  {
    id: 3,
    name: "sqlite",
  },
  {
    id: 4,
    name: "TypeORM",
  },
  {
    id: 5,
    name: "REST",
  },
  {
    id: 6,
    name: "GraphQL",
  },
];

export const cloudData = [
  {
    id: 1,
    name: "Azure",
  },
  {
    id: 2,
    name: "AWS",
  },
  {
    id: 3,
    name: "Ploi",
  },
  {
    id: 4,
    name: "GCP",
  },
  {
    id: 5,
    name: "Digital Ocean",
  },
];

export const toolsData = [
  {
    id: 1,
    name: "Docker",
  },
  {
    id: 2,
    name: "npm,yarn",
  },
  {
    id: 3,
    name: "Git/Bitbucket",
  },
  {
    id: 4,
    name: "Postman",
  },
  {
    id: 5,
    name: "SEO,GA4",
  },
  {
    id: 6,
    name: "Metasuite",
  },
  {
    id: 7,
    name: "CMS, Mailchimp",
  },
  {
    id: 8,
    name: "VS Code, PHP Storm",
  },
  {
    id: 8,
    name: "Table Plus, Forklift",
  },
  {
    id: 9,
    name: "Adobe Photoshop, Illustrator",
  },
  {
    id: 10,
    name: "Adobe Lightroom, Premier",
  },
];

export const fmData = [
  {
    id: 1,
    name: "Laravel",
  },
  {
    id: 2,
    name: "NodeJS",
  },
  {
    id: 3,
    name: "NestJS, Express",
  },
  {
    id: 4,
    name: "NextJS, NuxtJS",
  },
  {
    id: 5,
    name: "React, Redux, Material UI",
  },
  {
    id: 6,
    name: "Vue, Vuetify, Vuex",
  },
  {
    id: 7,
    name: "Angular",
  },
  {
    id: 9,
    name: "MERN, MEAN, MEVN",
  },
  {
    id: 10,
    name: "React Native",
  },
  {
    id: 11,
    name: "Bootstrap/jQuery",
  },
];

export const getIcons = (getCurrentColor) => {
  return [
    <SiMicrostrategy size={60} color={getCurrentColor} />,
    <SiSimpleanalytics size={60} color={getCurrentColor} />,
    <SiGooglemarketingplatform color={getCurrentColor} size={60} />,
    <MdOutlineDeveloperMode color={getCurrentColor} size={60} />,
    <CgWebsite color={getCurrentColor} size={60} />,
    <GoReport size={60} color={getCurrentColor} />,
    <FaLinkedin
      size={40}
      color={getCurrentColor}
      onClick={() => {
        window.location.href = "https://www.linkedin.com/in/sen-019-ram/";
      }}
    />,
  ];
};

export const projects = [
  {
    image: image1,
    title: "DEVELOPMENT",
    subtitle: "Getting tickets to the big show",
  },
  {
    image: image3,
    title: "DESIGN",
    subtitle: "Getting tickets to the big show",
  },
  {
    image: image2,
    title: "MARKETING",
    subtitle: "Getting tickets to the big show",
  },
  {
    image: image2,
    title: "ANALYTICS",
    subtitle: "Getting tickets to the big show",
  },
];

export const teamSocialIcons = [
  <FaFacebook size={"25px"} />,
  <FaLinkedin
    size={"25px"}
    onClick={() => {
      window.location.href = "https://www.linkedin.com/in/sen-019-ram/";
    }}
  />,
  <FaTwitter size={"25px"} />,
  <FaInstagram size={"25px"} />,
];
// export const teamData = [
//   {
//     name: "Andres Berlin",
//     role: `An avid open-source developer who loves to be creative and inventive. I have 20 years of experience in the field.`,
//     image: image5,
//     position: "Principal Software Engineer",
//   },

//   {
//     name: "Charles Keith",
//     role: `An avid open-source developer who loves to be creative and inventive. I have 20 years of experience in the field.`,
//     image: image6,
//     position: "UX Designer",
//   },
//   {
//     name: "Rachel Adams",
//     role: `An avid open-source developer who loves to be creative and inventive. I have 20 years of experience in the field.`,
//     image: image7,
//     position: "Product Design Manager",
//   },
//   {
//     name: "Rachel Adams",
//     role: `An avid open-source developer who loves to be creative and inventive. I have 20 years of experience in the field.`,
//     image: image6,
//     position: "Product Design Manager",
//   },
// ];

// export const contactControls = [
//   {
//     id: "name",
//     placeholder: "Your Name",
//     name: "from_name",
//     type: "text",
//     inputType: "input",
//     className:
//       "w-full bg-black text-textColor mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline",
//     divClassName: "",
//   },
//   {
//     id: "email",
//     placeholder: "Your Email Address",
//     type: "text",
//     inputType: "input",
//     name: "from_email",
//     className:
//       "w-full bg-black text-textColor mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline",
//     divClassName: "mt-8",
//   },
//   {
//     id: "message",
//     placeholder: "Your Message",
//     type: "text",
//     inputType: "textArea",
//     name: "message",
//     divClassName: "mt-8",
//     className:
//       "w-full sm:h-[6.5rem] bg-black lg:h-[14.5rem] md:h-[7.5rem]  text-textColor mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline",
//   },
// ];

export const footerIcons = [
  // <FaFacebook size={"30px"} />,
  <FaLinkedin
    size={"30px"}
    onClick={() => {
      window.location.href = "https://www.linkedin.com/in/sen-019-ram/";
    }}
  />,
  <FaFilePdf
    size={"30px"}
    onClick={() => {
      window.location.href = "/Resume.pdf";
    }}
  />,
  // <FaTwitter size={"30px"} />,
  // <FaInstagram size={"30px"} />,
];
