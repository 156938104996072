import { useState } from "react";
import {
  languagesData,
  fmData,
  dbData,
  cloudData,
  toolsData,
} from "../../data";
import Header from "../Header";
import { Element } from "react-scroll";
import Terminal from "../Terminal";
const Services = () => {
  const [currentItem, setCurrentItem] = useState(null);

  return (
    <Element
      id="services"
      className="bg-primaryBackground pt-10 pb-12  lg:pt[40px] lg:pb[40px]"
    >
      <div className="container mx-auto">
        <Header title={"My Stacks"} />
        <div className="flex flex-wrap justify-around  ">
          <div id="languages" className="w-full mx-5 my-5  xl:w-auto">
            <Terminal title={"languages"} data={languagesData} />
          </div>
          <div id="frameworks" className="w-full mx-5 my-5  xl:w-auto">
            <Terminal title={"frameworks"} data={fmData} />
          </div>
          <div id="db" className="w-full mx-5 my-5  xl:w-auto">
            <Terminal title={"database/API"} data={dbData} />
          </div>
          <div id="cloud" className="w-full mx-5 my-5  xl:w-auto">
            <Terminal title={"cloud services"} data={cloudData} />
          </div>
          <div id="tools" className="w-full mx-5 my-5  xl:w-auto">
            <Terminal title={"tools"} data={toolsData} />
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Services;
