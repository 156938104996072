import { Element, scroller } from "react-scroll";
import ParticlesComponent from "../Particles";
import logo from "../../images/logo.png";
import Typewriter from "typewriter-effect";
import { footerIcons, getIcons } from "../../data";

export default function Home() {
  function handleNavigate(id) {
    scroller.scrollTo(id, {
      smooth: true,
      duration: 1000,
    });
  }
  return (
    <Element id="home" className=" relative h-height648">
      {/* particles js component */}
      <ParticlesComponent />
      {/* particles js component */}
      <div className="absolute w-full">
        <div className="relative isolate">
          <div className="mx-5 md:mx-auto max-w-2xl   pt-10 mt-10 md:mt-0  md:py-40 lg:py50 ">
            <div className="text-center bg-zinc-800/80 p-5 rounded-lg  flex flex-col md:flex-row">
              <div className="flex-1 w-13 ">
                <img
                  className="w-40 mx-auto md:w-auto "
                  src={logo}
                  alt="SenthilRaj Ramalingam"
                />
              </div>
              <div className="flex-none">
                <h1 className="text-3xl font-bold tracking-tight text-teal-100 sm:text-5xl">
                  Hi, I am Senthil Raj
                </h1>
                <h3 className="text-2xl mt-4 font-bold tracking-tight text-red-500 sm:text-4xl">
                  <Typewriter
                    options={{
                      strings: [
                        "Full Stack Developer",
                        "Designer",
                        "Photographer",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h3>
                <div className="mt-5 flex items-center justify-center gap-x-6">
                  <button
                    onClick={() => handleNavigate("services")}
                    className="uppercase text-xl py-2 pr-2 pl-2 font-bold tracking-wide bg-bgRed text-gray-100  rounded-lg  focus:outline-none focus:shadow-outline"
                  >
                    See My Stacks
                  </button>
                </div>

                <ul className="flex justify-center mt-8 space-x-6 cursor-pointer">
                  {footerIcons.map((iconItem, i) => (
                    <li className="text-textSecondary" key={i}>
                      {iconItem}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
}
